import District from './District';
import Result from './Result';
import School from './School';
import Specialization from './Specialization';
import Teacher from './Teacher';

class Student {
	id: number;
	personalTitle: string;
	firstName: string;
	lastName: string;
	schoolClass: number;
	email: string;
	phone: string;
	approved: boolean;
	specialCertificate: boolean;
	city: string;
	zipCode: string;
	street: string;
	building: string;
	apartment: string;
	district: District;
	school: School;
	specialization: Specialization;
	teachers: Teacher[];
	result: Result;

	constructor(
		id: number,
		personalTitle: string,
		firstName: string,
		lastName: string,
		schoolClass: number,
		email: string,
		phone: string,
		approved: boolean,
		specialCertificate: boolean,
		city: string,
		zipCode: string,
		street: string,
		building: string,
		apartment: string,
		district: District,
		school: School,
		specialization: Specialization,
		teachers: Teacher[],
		result: Result,
	) {
		this.id = id;
		this.personalTitle = personalTitle;
		this.firstName = firstName;
		this.lastName = lastName;
		this.schoolClass = schoolClass;
		this.email = email;
		this.phone = phone;
		this.approved = approved;
		this.specialCertificate = specialCertificate;
		this.city = city;
		this.zipCode = zipCode;
		this.street = street;
		this.building = building;
		this.apartment = apartment;
		this.district = district;
		this.school = school;
		this.specialization = specialization;
		this.teachers = teachers;
		this.result = result;
	}

	public static fromApiResponse(data: any) {
		return new Student(
			data.id ? data.id : 0,
			data.personal_title ? data.personal_title : '',
			data.first_name ? data.first_name : '',
			data.last_name ? data.last_name : '',
			data.class ? data.class : 1,
			data.email ? data.email : '',
			data.phone ? data.phone : '',
			data.approved === 1,
			data.special_certificate === 1,
			data.city ? data.city : '',
			data.zip_code ? data.zip_code : '',
			data.street ? data.street : '',
			data.building ? data.building : '',
			data.apartment ? data.apartment : '',
			District.fromApiResponse(data.district ? data.district : {}),
			School.fromApiResponse(data.school ? data.school : {}),
			Specialization.fromApiResponse(data.specialization ? data.specialization : {}),
			data.teachers ? data.teachers.map((t: any) => Teacher.fromApiResponse(t)) : [],
			Result.fromApiResponse(data.results ? data.results : {}),
		);
	}
}

export default Student;
