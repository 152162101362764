import React, { useContext, useEffect, useState } from 'react';
import { getUserData } from '../../api/user-api';
import Student from '../../model/Student';
import { loginPage } from '../../store/fixed-routing';
import { Box, Button, Divider, Grid, Typography, useTheme } from '@mui/material';
import AuthContext from '../../store/auth-context';
import OlympicsContext from '../../store/olympics-context';
import { useNavigate } from 'react-router-dom';
import SnackbarContext from '../../store/snackbar-context';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Loader from '../../ui/Loader';
import UserData from './UserData';
import UserResults from './UserResults';

type UserDataTextProps = {
	title?: string;
	text: string;
};
export const UserDataText = ({ title, text }: UserDataTextProps) => {
	const { palette } = useTheme();

	return (
		<Grid
			item
			container
			xs={6}
		>
			<Typography
				sx={{
					color: palette.primary.main,
					fontSize: '18px !important',
				}}
			>
				{title && (
					<Box
						component={'span'}
						sx={{
							fontWeight: 600,
						}}
					>
						{title}
					</Box>
				)}
				{text}
			</Typography>
		</Grid>
	);
};

const UserPanel = () => {
	const { palette } = useTheme();
	const { logout, token } = useContext(AuthContext);
	const { olympics } = useContext(OlympicsContext);
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const { setMsg } = useContext(SnackbarContext);
	const [student, setStudent] = useState<Student | null>(null);

	const downloadData = () => {
		setLoading(true);
		getUserData(token)
			.then((response) => {
				if (response.status === 200) {
					setStudent(Student.fromApiResponse(response.data));
				} else {
					console.log(response);
					setMsg({ msg: response.data });
				}
				setLoading(false);
			})
			.catch((exception) => {
				console.error(exception);
				if (exception.response.status === 401 || exception.response.status === 403) {
					logout();
					navigate(loginPage);
					setMsg({ msg: 'Nie masz uprawnień albo Twoja sesja wygasła' });
				} else {
					const msg = exception?.response?.data?.message
						? exception?.response?.data?.message
						: 'Błąd serwera. Spróbuj za chwilę';
					setMsg({ msg });
				}
				setLoading(false);
			});
	};

	useEffect(() => {
		downloadData();
	}, []);

	return (
		<Grid
			item
			container
			justifyContent={'start'}
			height={'100%'}
			width={'100%'}
			maxWidth={'650px'}
			flexDirection={'column'}
			gap={'24px'}
		>
			<Grid
				item
				container
				justifyContent={'space-between'}
			>
				<Typography
					variant={'h3'}
					fontWeight={'500'}
					color={palette.primary.main}
				>
					Edycja {olympics?.edition}
				</Typography>
				<Button
					size={'small'}
					startIcon={<LockOpenIcon />}
					onClick={() => logout()}
					sx={{
						fontSize: '14px',
					}}
				>
					Wyloguj
				</Button>
			</Grid>
			<Divider
				variant={'fullWidth'}
				sx={{
					backgroundColor: palette.primary.main,
					borderBottomWidth: '3px',
				}}
			/>
			{loading && <Loader />}
			{!loading && (
				<>
					<UserData student={student} />
					<UserResults student={student} />
				</>
			)}
		</Grid>
	);
};

export default UserPanel;
