import Student from '../../model/Student';
import { Divider, Grid, Typography, useTheme } from '@mui/material';
import React, { useContext } from 'react';
import { UserDataText } from './UserPanel';
import { GridProps } from '@mui/material/Grid/Grid';
import OlympicsContext from '../../store/olympics-context';

const Row = (props: GridProps) => {
	return (
		<Grid
			item
			container
			justifyContent={'space-between'}
			{...props}
		>
			{props.children}
		</Grid>
	);
};

type Props = {
	student: Student | null;
};
const UserResults = ({ student }: Props) => {
	const { palette } = useTheme();
	const { olympics } = useContext(OlympicsContext);
	const areResultsPublished =
		olympics?.firstStagePublished ||
		olympics?.secondAStagePublished ||
		olympics?.secondBStagePublished ||
		olympics?.thirdAStagePublished ||
		olympics?.thirdBStagePublished;

	return (
		<>
			<Grid
				item
				container
				justifyContent={'space-between'}
				mt={5}
			>
				<Typography
					variant={'h4'}
					fontWeight={'500'}
					color={palette.primary.main}
				>
					Wyniki
				</Typography>
			</Grid>
			<Divider
				variant={'fullWidth'}
				sx={{
					backgroundColor: palette.secondary.light,
					borderBottomWidth: '3px',
				}}
			/>
			{!areResultsPublished && (
				<Row>
					<UserDataText text={'Nie opublikowano jeszcze wyników'} />
				</Row>
			)}
			{olympics?.firstStagePublished && (
				<>
					<Row>
						<UserDataText
							title={'Etap I'}
							text={''}
						/>
					</Row>
					<Row>
						<UserDataText
							title={'Punkty: '}
							text={`${student?.result.firstStagePoints}`}
						/>
						<UserDataText
							title={'Zakwalifikowany do etapu II: '}
							text={`${student?.result.firstStageQualified ? 'Tak' : 'Nie'}`}
						/>
					</Row>
				</>
			)}
			{olympics?.secondAStagePublished && (
				<>
					<Divider variant={'fullWidth'} />
					<Row>
						<UserDataText
							title={'Etap II część pisemna'}
							text={''}
						/>
					</Row>
					<Row>
						<UserDataText
							title={'Część I: '}
							text={`${student?.result.secondAOneStagePoints} pkt`}
						/>
						<UserDataText
							title={'Część II: '}
							text={`${student?.result.secondATwoStagePoints} pkt`}
						/>
					</Row>
					<Row>
						<UserDataText
							title={'Numer tematu: '}
							text={`${student?.result.secondAStageTopic}`}
						/>
						<UserDataText
							title={'Zakwalifikowany do części ustnej: '}
							text={`${student?.result.secondAStageQualified ? 'Tak' : 'Nie'}`}
						/>
					</Row>
				</>
			)}
			{olympics?.secondBStagePublished && (
				<>
					<Divider variant={'fullWidth'} />
					<Row>
						<UserDataText
							title={'Etap II część ustna'}
							text={''}
						/>
					</Row>
					<Row>
						<UserDataText
							title={'Część I: '}
							text={`${student?.result.secondBOneStagePoints} pkt`}
						/>
						<UserDataText
							title={'Część II: '}
							text={`${student?.result.secondBTwoStagePoints} pkt`}
						/>
					</Row>
					<Row>
						<UserDataText
							title={'Część III: '}
							text={`${student?.result.secondBThreeStagePoints} pkt`}
						/>
						<UserDataText
							title={'Łącznie: '}
							text={`${student?.result.secondBStageTotalPoints} pkt`}
						/>
					</Row>
					<Row>
						<UserDataText
							title={'Zakwalifikowany do etapu III: '}
							text={`${student?.result.secondAStageQualified ? 'Tak' : 'Nie'}`}
						/>
					</Row>
				</>
			)}
			{olympics?.thirdAStagePublished && (
				<>
					<Divider variant={'fullWidth'} />
					<Row>
						<UserDataText
							title={'Etap III część pisemna'}
							text={''}
						/>
					</Row>
					<Row>
						<UserDataText
							title={'Punkty: '}
							text={`${student?.result.thirdAStagePoints} pkt`}
						/>
						<UserDataText
							title={'Temat: '}
							text={`${student?.result.thirdAStageTopic}`}
						/>
					</Row>
					<Row>
						<UserDataText
							title={'Zakwalifikowany do części ustnej: '}
							text={`${student?.result.thirdAStageQualified ? 'Tak' : 'Nie'}`}
						/>
					</Row>
				</>
			)}
			{olympics?.thirdBStagePublished && (
				<>
					<Divider variant={'fullWidth'} />
					<Row>
						<UserDataText
							title={'Etap III część ustna'}
							text={''}
						/>
					</Row>
					<Row>
						<UserDataText
							title={'Część I: '}
							text={`${student?.result.thirdBOneStagePoints} pkt`}
						/>
						<UserDataText
							title={'Część II: '}
							text={`${student?.result.thirdBTwoStagePoints} pkt`}
						/>
					</Row>
				</>
			)}
			{olympics?.thirdAStagePublished && (
				<>
					<Divider variant={'fullWidth'} />
					<Row>
						<UserDataText
							title={'Uzyskany tytuł: '}
							text={student?.result?.finalTitle ? student?.result?.finalTitle : 'Brak'}
						/>
					</Row>
				</>
			)}
		</>
	);
};

export default UserResults;
