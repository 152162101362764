import Student from '../../model/Student';
import { Divider, Grid, Typography, useTheme } from '@mui/material';
import React from 'react';
import { UserDataText } from './UserPanel';
import { GridProps } from '@mui/material/Grid/Grid';
import Teacher from '../../model/Teacher';

const Row = (props: GridProps) => {
	return (
		<Grid
			item
			container
			justifyContent={'space-between'}
			{...props}
		>
			{props.children}
		</Grid>
	);
};

type Props = {
	student: Student | null;
};
const UserData = ({ student }: Props) => {
	const { palette } = useTheme();

	return (
		<>
			<Grid
				item
				container
				justifyContent={'space-between'}
				mt={5}
			>
				<Typography
					variant={'h4'}
					fontWeight={'500'}
					color={palette.primary.main}
				>
					Twoje dane
				</Typography>
			</Grid>
			<Divider
				variant={'fullWidth'}
				sx={{
					backgroundColor: palette.secondary.main,
					borderBottomWidth: '3px',
				}}
			/>
			<Row>
				<UserDataText
					title={'Imię i nazwisko: '}
					text={`${student?.personalTitle} ${student?.firstName} ${student?.lastName}`}
				/>
			</Row>
			<Row>
				<UserDataText
					title={'Adres: '}
					text={`${student?.street} ${student?.building} ${student?.apartment}, ${student?.zipCode} ${student?.city}`}
				/>
			</Row>
			<Row>
				<UserDataText
					title={'Szkoła: '}
					text={`${student?.school.name} ${student?.school.city}`}
				/>
				<UserDataText
					title={'Klasa: '}
					text={`${student?.schoolClass}`}
				/>
			</Row>
			<Row>
				<UserDataText
					title={'Specjalizacja: '}
					text={`${student?.specialization.name}`}
				/>
				<UserDataText
					title={'Opiekunowie: '}
					text={`${student?.teachers.map((t: Teacher) => `${t.firstName} ${t.lastName}`).join(', ')}`}
				/>
			</Row>
		</>
	);
};

export default UserData;
