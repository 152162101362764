import { createContext, FC, ReactElement, ReactNode, useReducer } from 'react';
import Page from '../model/Page';
import Menu from '../model/Menu';
import Settings from '../model/Settings';
import Post from '../model/Post';

type ContentContextType = {
	pages: [];
	registrationEnabled: boolean;
	menu: Menu;
	settings: Settings;
	setPages: (pages: Page[]) => void;
	setPosts: (posts: Post[]) => void;
	setMenu: (menu: Menu) => void;
	setSettings: (settings: Settings) => void;
};

type ActionType = {
	type: string;
	value?: any;
};

const SET_PAGES = 'setPages';
const SET_MENU = 'setMenu';
const SET_SETTINGS = 'setSettings';

const INITIAL_CONTENT_STATE: ContentContextType = {
	registrationEnabled: process.env.REACT_APP_NAME === 'OLiJP',
	pages: [],
	menu: {} as Menu,
	settings: {} as Settings,
	setPages: (pages: Page[]) => {},
	setPosts: (posts: Post[]) => {},
	setMenu: (menu: Menu) => {},
	setSettings: (settings: Settings) => {},
};

const contentReducer = (state: ContentContextType, action: ActionType): ContentContextType => {
	switch (action.type) {
		case SET_PAGES: {
			return {
				...state,
				pages: action.value,
			};
		}
		case SET_MENU: {
			return {
				...state,
				menu: action.value,
			};
		}
		case SET_SETTINGS: {
			return {
				...state,
				settings: action.value,
			};
		}
		default:
			return state;
	}
};

export const ContentContextProvider = ({ children }: { children: ReactNode }) => {
	const [contentState, dispatch] = useReducer(contentReducer, INITIAL_CONTENT_STATE, undefined);

	const setPages = (pages: Page[]) => {
		dispatch({ type: SET_PAGES, value: pages });
	};
	const setMenu = (menu: Menu) => {
		dispatch({ type: SET_MENU, value: menu });
	};
	const setSettings = (settings: Settings) => {
		dispatch({ type: SET_SETTINGS, value: settings });
	};

	const contextValue: ContentContextType = {
		...contentState,
		setPages: setPages,
		setMenu: setMenu,
		setSettings: setSettings,
	};
	return <ContentContext.Provider value={contextValue}>{children}</ContentContext.Provider>;
};

const ContentContext = createContext<ContentContextType>({
	...INITIAL_CONTENT_STATE,
});

export default ContentContext;
