import { createContext, ReactNode, useContext, useEffect, useReducer } from 'react';
import Olympics from '../model/Olympics';
import { getAllDistricts, getOlympicsSettings } from '../api/content-api';
import { loginPage } from './fixed-routing';
import AuthContext from './auth-context';
import SnackbarContext from './snackbar-context';
import { useNavigate } from 'react-router-dom';
import District from '../model/District';

type OlympicsContextType = {
	olympics: Olympics | null;
	districts: District[];
	districtFilterId: number;
	setOlympics: (olympics: Olympics) => void;
	setDistrictFilterId: (id: number) => void;
	refresh: () => void;
};

type ActionType = {
	type: string;
	value?: any;
};

const SET_OLYMPICS = 'setOlympics';
const SET_DISTRICTS = 'setDistricts';
const SET_DISTRICT_FILTER_ID = 'setDistrictFilterId';

const INITIAL_OLYMPICS_STATE: OlympicsContextType = {
	olympics: null,
	districts: [],
	districtFilterId: -1,
	setOlympics: (olympics) => {},
	setDistrictFilterId: (id) => {},
	refresh: () => {},
};

const olympicsReducer = (state: OlympicsContextType, action: ActionType): OlympicsContextType => {
	switch (action.type) {
		case SET_OLYMPICS: {
			return {
				...state,
				olympics: action.value,
			};
		}
		case SET_DISTRICTS: {
			return {
				...state,
				districts: action.value,
			};
		}
		case SET_DISTRICT_FILTER_ID: {
			return {
				...state,
				districtFilterId: action.value,
			};
		}
		default:
			return state;
	}
};

export const OlympicsContextProvider = ({ children }: { children: ReactNode }) => {
	const [olympicsState, dispatch] = useReducer(olympicsReducer, INITIAL_OLYMPICS_STATE, undefined);
	const { token, logout } = useContext(AuthContext);
	const { setMsg } = useContext(SnackbarContext);
	const navigate = useNavigate();

	const setOlympics = (olympics: Olympics) => {
		dispatch({ type: SET_OLYMPICS, value: olympics });
	};

	const setDistrictFilterId = (id: number) => {
		dispatch({ type: SET_DISTRICT_FILTER_ID, value: id });
	};

	const getDistricts = () => {
		getAllDistricts()
			.then((response) => {
				if (response.status === 200) {
					const districts = response.data.map((d: any) => District.fromApiResponse(d));
					dispatch({ type: SET_DISTRICTS, value: districts });
				} else {
					console.log(response);
					setMsg({ msg: response.data });
				}
			})
			.catch((exception) => {
				console.error(exception);
				setMsg({ msg: 'Błąd serwera. Spróbuj za chwilę' });
			});
	};

	const refresh = () => {
		getOlympicsSettings(token)
			.then((response) => {
				if (response.status === 200) {
					dispatch({ type: SET_OLYMPICS, value: Olympics.fromApiResponse(response.data) });
				} else {
					console.log(response);
					setMsg({ msg: response.data });
				}
			})
			.catch((exception) => {
				console.error(exception);
				if (exception.response.status === 401 || exception.response.status === 403) {
					logout();
					navigate(loginPage);
					setMsg({ msg: 'Nie masz uprawnień albo Twoja sesja wygasła' });
				} else {
					setMsg({ msg: 'Błąd serwera. Spróbuj za chwilę' });
				}
			});
	};

	useEffect(() => {
		getDistricts();
		refresh();
	}, []);

	const contextValue: OlympicsContextType = {
		...olympicsState,
		setOlympics,
		setDistrictFilterId,
		refresh,
	};
	return <OlympicsContext.Provider value={contextValue}>{children}</OlympicsContext.Provider>;
};

const OlympicsContext = createContext<OlympicsContextType>({
	...INITIAL_OLYMPICS_STATE,
});

export default OlympicsContext;
