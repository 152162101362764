import { Box, Grid, styled, Typography, useTheme } from '@mui/material';
import { registrationPage } from '../../store/fixed-routing';
import { Link } from 'react-router-dom';
import { StyledNavLink } from './Header';
import { removeTrailingSlash } from '../../utils/string-tools';
import { MenuItem } from '../../model/Menu';
import { useContext } from 'react';
import ContentContext from '../../store/content-context';
import { getLineHighlight } from '../../styles/theme';

type Props = {
	menu: any;
	show: boolean;
	signIn: string;
};

const StyledTypography = styled(Typography)(({ theme }) => ({
	color: theme.palette.primary.light,
	fontSize: '18px',
	fontWeight: 400,
	letterSpacing: '0.18px',
}));

const ExpandMenu = ({ menu, show, signIn }: Props) => {
	const { palette } = useTheme();
	const { registrationEnabled } = useContext(ContentContext);

	return (
		<Grid
			component='section'
			container
			justifyContent={'space-between'}
			alignContent='baseline'
			py={{ xs: '12px', lg: '24px 0 30px' }}
			sx={{
				pointerEvents: `${!show ? 'none' : 'auto'}`,
				display: 'flex',
				zIndex: `${!show ? '-1' : '3'}`,
				opacity: `${!show ? '0' : '1'}`,
				position: 'absolute',
				transform: `${!show ? 'translateY(-50%)' : 'translateY(0)'}`,
				width: '100%',
				minWidth: 'calc(100% + 10px)',
				boxShadow: `60px 0px 0px 0px ${palette.primary.main}, -60px 0px 0px 0px ${palette.primary.main}`,
				minHeight: `${!show ? '0' : 'auto'}`,
				top: '100%',
				left: 0,
				backgroundColor: palette.primary.main,
			}}
		>
			<Grid
				item
				container
				xs={8.5}
				gap='64px'
			>
				{menu?.map((item: any, index: number) => {
					return (
						<Grid
							key={index}
							item
							pb='24px'
							textAlign={{ xs: 'center', md: 'left' }}
						>
							<StyledNavLink to={removeTrailingSlash(item?.url)}>
								<StyledTypography
									sx={{
										color: palette.primary.light,
										fontSize: '22px',
										fontWeight: 500,
										letterSpacing: '0.22px',
									}}
								>
									{item.title}
								</StyledTypography>
							</StyledNavLink>
							{item?.subMenu?.map((item: MenuItem, index: number) => (
								<Grid
									item
									key={'link-' + index}
									container
									justifyContent={'start'}
								>
									<StyledNavLink to={removeTrailingSlash(item?.url)}>
										<StyledTypography sx={{ paddingTop: '16px' }}>{item?.title}</StyledTypography>
									</StyledNavLink>
								</Grid>
							))}
						</Grid>
					);
				})}
			</Grid>

			{registrationEnabled && (
				<Grid
					item
					container
					alignContent={'flex-end'}
					xs={2.5}
					sx={{
						minHeight: '400px',
					}}
				>
					<Grid
						item
						alignSelf='end'
						paddingBottom='40px'
					>
						<Link
							to={registrationPage}
							style={{ textDecoration: 'none' }}
						>
							<Box
								sx={{
									display: 'inline-block',
									backgroundImage: `url(${getLineHighlight().long})`,
									backgroundRepeat: 'round',
									backgroundPosition: '0 0',
									height: '90%',
									width: '115%',
									color: palette.primary.main,
									fontSize: '18px',
									letterSpacing: '0.18px',
									fontWeight: '500',
									textDecoration: 'underline',
									padding: '2px 0px 2px 20px',
								}}
							>
								{signIn}
							</Box>
						</Link>
					</Grid>
				</Grid>
			)}
		</Grid>
	);
};
export default ExpandMenu;
