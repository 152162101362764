import { Divider, Grid, Typography, useTheme } from '@mui/material';

type Props = {
	text: string;
	dividerColor?: string;
	sx?: any;
};

const TextWithDivider = ({ text, dividerColor, sx }: Props) => {
	const { palette, typography } = useTheme();
	return (
		<Grid
			display={'flex'}
			flexDirection={'column'}
			width={'100%'}
			gap={'4px'}
			sx={{
				...sx,
			}}
		>
			<Typography
				fontSize={typography.h4.fontSize}
				fontWeight={'500'}
				color={palette.primary.main}
			>
				{text}
			</Typography>
			<Divider
				variant={'fullWidth'}
				sx={{
					bgcolor: dividerColor ? dividerColor : palette.primary.main,
					borderBottomWidth: '3px',
				}}
			/>
		</Grid>
	);
};

export default TextWithDivider;
